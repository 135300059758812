import React, { Component } from 'react';
import { Panel } from "react-bootstrap";
import { Row, Column } from 'simple-flexbox';

import ComponentHeader from "../../componentHeader";
import MyCommissionIcon from '../../../components/icons/methods';

import '../../../assets/css/myAccount.css';

import { crudActions } from "../../../services/crudActions";
import Table from "../Table";
import { whiteLabel } from "../../../config";
import Alert from "../Alert";

class MyCommission extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      columns: [{
        value: "currency",
        label: "Currency"
      }, {
        value: "method",
        label: "Payin Method",
      }, {
        value: "payoutMethod",
        label: "Payout Method",
      }, {
        value: "buyAgentFee",
        label: "Payin %"
      }, {
        value: "payoutAgentFee",
        label: "Payout %"
      }, {
        value: "midId",
        label: "MIDs"
      }],

      commissions: [],
      showAlert: false
    };
  }

  componentDidMount() {
    crudActions.get(`v1/agent/commissions`).then(
      (response) => {
        if (response) {
          this.setState({
            commissions: response,
            isLoading: false
          });
        }
      }
    ).catch(
      err => {
        if (err && err.message) {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: err.message
          });
        }
      }
    );
  };

  tableData = () => {
    const { commissions } = this.state;
    return commissions.map(item => {
      return Object.assign({}, item, {
        buyAgentFee: `${item.buyAgentFee}%`,
        payoutAgentFee: `${item.payoutAgentFee}%`,
        midId: item.midId.join(', ')
      });
    });
  };

  onConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: ""
    });
  };

  render() {
    const { columns, showAlert, alertType, alertMessage } = this.state;

    return (
      <Row flexGrow={ 1 } className='module apidata my-account my-commission' vertical='start'>
        <Column flexGrow={ 1 } className='overflow-x-auto'>
          <ComponentHeader
            title="My Commission"
            img={ MyCommissionIcon[whiteLabel].commission }
          />
          <Row className="dashboard-row" flexGrow={ 1 } horizontal='start' alignSelf="start" style={ {width: '100%'} }>
            <Column className="table-column" flexGrow={ 1 } vertical='start' style={ {padding: 15, width: '100%'} }>
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    AGENT COMMISSION
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div className="panel-content">
                    <Table
                      columns={ columns }
                      headerColor={ whiteLabel === "trustbucks" ? "#263e0f" : whiteLabel === "t365" ? "#25282a" : "#223679" }
                      textColor={ "#FFFFFF" }
                      data={ this.tableData() }
                      isStriped={ false }
                      addClassName
                    />
                  </div>
                </Panel.Body>
              </Panel>
            </Column>
          </Row>
        </Column>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor={ "#DD6B55" }
            onConfirm={ this.onConfirm }
          />
        )}
      </Row>
    );
  }
}

export default MyCommission;
