import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import dashboard from '../assets/images/dashboard.png';
import StarMidsLogo from '../assets/images/starmids.png';
import StarMids from '../components/icons/methods';
import { whiteLabel } from '../config';
import { menuList } from '../utils/menuList';
import '../assets/css/trustbucks/apiData.scss';

import logout from '../assets/images/logout.png';
import { loginActions } from './../services/loginActions';

import { Column, Row } from 'simple-flexbox';
import { connect } from 'react-redux';
import * as actions from '../actions';

import { FETCH_PERMISSIONS } from '../actions/types';
const store =  require('../reducers/index');

class Header extends Component {
  state = {
    isBurgerOpen: false,
    menu: menuList,

    access: [],
    roleId: ""
  };

  node = null;
  burgerNode = null;
  subscribeFunction = null;

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  }

  handleClick = (e) => {
    if ((this.node && this.node.contains(e.target)) || (this.burgerNode && this.burgerNode.contains(e.target))) {
      return;
    }

    this.handleClickOutside();
  };

  handleClickOutside = () => {
    this.setState({
      isBurgerOpen: false
    });
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access,
          roleId: state.roleId
        });
      }
    });
  }

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  checkPageAccessCategory = (categoryName) => {
    const { access, roleId } = this.state;
    if (categoryName === "MY_ACCOUNT" || categoryName === "AFFILIATE_LINKS") {
      return true;
    } else if (categoryName === "COMPANIES" || categoryName === "PSPS") {
      return roleId === "MASTER_TECH";
    }

    const foundPermissions = access.filter(elem => elem.category === categoryName);
    return foundPermissions.some(elem => elem.state);
  };

  logoutHandler = () => {
    loginActions.logout();
  };

  onBurgerClick = () => {
    this.setState({
      isBurgerOpen: !this.state.isBurgerOpen
    });
  };

  onNavClick = (link) => {
    this.setState({
      isBurgerOpen: false
    });

    if (link === "Log out") {
      this.logoutHandler();
    }
  };

  displayMenuItem = (menu) => {
    const { access } = this.state;
    if (
      menu.submenu.find((elem) => elem.access === "MY_ACCOUNT") ||
      menu.submenu.find((elem) => elem.access === "API")
    ) {
      return true;
    }

    const foundItems = access.filter((permission) =>
      menu.submenu.some(
        (elem) =>
          permission[elem.permission ? "permission" : "category"] ===
          elem.access
      )
    );
    return foundItems.some((elem) => elem.state);
  };

  sortMenu = (menu) => {
    menu.sort((elemA, elemB) => {
      if (elemA.name < elemB.name)
        return -1;
      if (elemA.name > elemB.name)
        return 1;
      return 0;
    });

    return menu;
  };

  render() {
    const { isBurgerOpen, menu } = this.state;
    const { message, userLoggedIn } = this.props;

    const logo = (
      <NavLink to='/dashboard' className="header-logo" onClick={ () => this.onNavClick() }>
        {StarMids[whiteLabel].logo !== undefined ? (
          <img
            className={ `${whiteLabel} logoIcon` }
            src={ StarMids[whiteLabel].logo }
            alt={ StarMids[whiteLabel].text }
          />
        ) : (
          <>
            <span className="logo-text">{ StarMids[whiteLabel].text }</span>
            <br/>
            <span className="powered-by"> POWERED BY STAR <img src={ StarMidsLogo } className="starmids-logo" alt="StarMids"/> MIDS </span>
          </>
        )}
      </NavLink>
    );

    return (
      <Row horizontal='spaced' vertical='center' flexGrow={ 1 } className={ `${whiteLabel} navBar navbar` }>
        <Row horizontal={ userLoggedIn ? `start` : `center` } vertical='center' className="navBar-inner">
          { logo }
        </Row>
        { userLoggedIn && (
          <div
            ref={ node => this.burgerNode = node }
            className={ "burger-container " + (isBurgerOpen ? "change" : "") }
            onClick={ this.onBurgerClick }
          >
            <div className="burger-bar1"></div>
            <div className="burger-bar2"></div>
            <div className="burger-bar3"></div>
          </div>
        )}

        {<div ref={ node => this.node = node } className={ `burger-content ${isBurgerOpen ? 'open' : ''}` }>
          <div className={ `logo ${whiteLabel}` }>
            { logo }
          </div>
          <div className="menu">
            {menu.map((menuItem, i) => {
              return (
                this.displayMenuItem(menuItem) && (
                  <Column key={ i } className="menublock">
                    <Column className="header">{menuItem.header}</Column>
                    {this.sortMenu(menuItem.submenu).map((item, j) => {
                      return (
                        (item.permission
                          ? this.checkPageAccess(item.access)
                          : this.checkPageAccessCategory(item.access)) && (
                          <NavLink
                            key={ j }
                            to={ item.url }
                            className="menuItem"
                            onClick={ () => this.onNavClick() }
                          >
                            {` ${item.name} `}
                          </NavLink>
                        )
                      );
                    })}
                  </Column>
                )
              );
            })}
            <div className="logout-item">
              <div
                className="menuItem"
                onClick={ () => this.onNavClick("Log out") }
              >
                Log out
              </div>
            </div>
          </div>
        </div>
        }

        { userLoggedIn && (
          <Row horizontal='end' vertical='center' className="group-icons">
            <Row horizontal='center' vertical='center' >
              <NavLink to='/dashboard' >
                <Column horizontal='center' vertical='center' className="icon">
                  <img src={ dashboard } className="" height="25" alt="" />
                  <div style={ {fontSize: 14} }>Dashboard</div>
                </Column>
              </NavLink>
            </Row>
            <Row horizontal='center' vertical='center' >
              <div onClick={ this.logoutHandler } className='logout-icon'>
                <Column horizontal='center' vertical='center' className="icon">
                  <img src={ logout } className="" height="25" alt="" />
                  <div style={ {fontSize: 14} }>Log out</div>
                </Column>
              </div>
            </Row>
          </Row>
        )}
        {message && message.show && (
          <div style={ {position: 'fixed', width: '100%', height:'100px'} }>
            <Row horizontal='center' vertical='center'>
              <div className="popup-window">{message.message}</div>
            </Row>
          </div>
        )}
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    message: state.message,
  };
}

export default connect(mapStateToProps, actions)(Header);